<template>
  <div>
    <Breadcrumbs />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              {{ $t("Icon") }}
            </th>
            <th class="text-left">{{ $t("Authentication Name") }}</th>
            <th class="text-left">
              {{ $t("Authentication Adapter") }}
            </th>
            <th class="text-left">
              {{ $t("Description") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.type" class="text-left">
            <td class="pl-4">
              <v-img
                :src="require('@/assets/sources/' + item.type + '.png')"
                max-width="40"
              ></v-img>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.description }}</td>
            <td class="text-center">
              <v-btn
                elevation="0"
                color="primary"
                outlined
                @click.stop="show_sheet(item)"
              >
                {{ $t("source.Create Authentication Method") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component :is="sheet_editor" type="create" />
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";

import SmsEditor from "@/views/method/editor/Sms";
import WeiBoEditor from "@/views/method/editor/WeiBo";
import WechatEditor from "@/views/method/editor/Wechat";
import WechatRZKCEditor from "@/views/method/editor/Wechat_RZKC";
import WechatOfficialEditor from "@/views/method/editor/WechatOfficial";
import WechatWebSiteEditor from "@/views/method/editor/WechatWebSite";
import { mapMutations } from "vuex";
const EDITORS = {
  SMS: SmsEditor,
  WEIBO: WeiBoEditor,
  WECHAT: WechatEditor,
  WECHAT_RZKC: WechatRZKCEditor,
  WECHAT_OFFICIAL: WechatOfficialEditor,
  WECHAT_WEBSITE: WechatWebSiteEditor
};

export default {
  name: "CreateSource",
  data() {
    return {
      show: true,
      sheet_editor: null,
      sources: [
        {
          name: "微博登录认证方式",
          type: "WEIBO",
          description: "使用微博登录进行认证"
        },
        {
          name: "短信接口认证方式",
          type: "SMS",
          description: "使用短信接口进行认证"
        },
        // {
        //   name: "微信网站认证方式",
        //   type: "WECHAT_WEBSITE",
        //   description: "使用微信网站登录进行认证"
        // },
        // {
        //   name: "微信公众号身份源",
        //   type: "WECHAT_OFFICIAL",
        //   description: "使用微信公众号进行认证"
        // },
        {
          name: "代理微信登录认证方式",
          type: "WECHAT_RZKC",
          description: "使用瑞智康诚的公众号代理微信登录进行认证"
        }
      ]
    };
  },
  created() {
    this.change_breadcrumbs([
      { text: "Authentication Method" },
      { text: "Create Authentication Method" }
    ]);
  },
  methods: {
    ...mapMutations(["change_breadcrumbs"]),
    show_sheet(item) {
      this.sheet_editor = EDITORS[item.type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  components: {
    Breadcrumbs,
    SmsEditor,
    WeiBoEditor,
    WechatEditor,
    WechatRZKCEditor,
    WechatOfficialEditor,
    WechatWebSiteEditor
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
